<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="p-1">
        <b-row>
          <b-col
            v-if="$can('create', 'post')"
            class="col d-flex justify-content-end"
          >
            <b-button variant="primary" @click="addPost">
              <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPostsListTable"
        class="position-relative table-white-space"
        :items="fetchPosts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(category)="data">
          <b-link
            class="text-primary cursor-pointer"
            @click="updatePost(data.item)"
          >
            {{ resolveCategory(data.item.category_id) }}
          </b-link>
        </template>

        <template #cell(title)="data">
          <div style="width: 400px">
            <b-link
              :href="data.item.link"
              target="_blank"
              style="white-space: pre-line"
            >
              {{ data.item.title }}
            </b-link>
          </div>
        </template>

        <template #cell(title_en)="data">
          <div style="width: 400px">
            <b-link target="_blank" style="white-space: pre-line">
              {{ data.item.title_en }}
            </b-link>
          </div>
        </template>
        <template #cell(short_description_en)="data">
          <b-link class="text-white cursor-pointer" target="_blank">
            {{ data.item.short_description_en }}
          </b-link>
        </template>

        <template #cell(image)="data">
          <b-link
            v-if="checkImg(data.item.image) == false"
            :href="baseUrl + data.item.image"
            target="_blank"
            class="cursor-pointer"
          >
            <b-img
              :src="baseUrl + data.item.image"
              fluid
              style="width: 80px"
              class="mr-2 rounded"
            />
          </b-link>
          <b-link
            v-if="checkImg(data.item.image) == true"
            :href="data.item.image"
            target="_blank"
            class="cursor-pointer"
          >
            <b-img
              :src="data.item.image"
              fluid
              style="width: 80px"
              class="mr-2 rounded"
            />
          </b-link>
          <!-- </div> -->
        </template>

        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(created_at)="data">
          {{
            data.item.created_at
              ? formatDateTime(data.item.created_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(updated_at)="data">
          {{
            data.item.updated_at
              ? formatDateTime(data.item.updated_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            v-if="$can('update', 'post')"
            variant="primary"
            class="btn-icon btn-sm mr-50"
            @click="updatePost(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject')"
            v-if="$can('delete', 'post')"
            variant="danger"
            class="btn-icon btn-sm"
            @click="deletePost(data.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPosts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Add Post Modal -->
    <add-post-modal
      :post-categories="postCategories"
      @refetch-data="refetchData"
    />

    <!-- Update Post Modal -->
    <update-post-modal
      :post-categories="postCategories"
      :post-detail.sync="postDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import usePostsList from "./usePostList";
import frontendStoreModule from "@/views/frontend/frontendStoreModule";
import baseUrl from "@/libs/base-url";

export default {
  components: {
    AddPostModal: () => import("./AddPostModal.vue"),
    UpdatePostModal: () => import("./UpdatePostModal"),
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      postDetail: null,
    };
  },
  methods: {
    addPost() {
      this.$bvModal.show("modal-add-post");
    },
    deletePost(postId) {
      this.$swal({
        title: "Are you sure ?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const postData = {
            id: postId,
          };
          store.dispatch("frontend/deletePost", postData).then((response) => {
            if (response.data.code == "00") {
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your item has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.refetchData();
            }
          });
        }
      });
    },

    updatePost(datas) {
      this.postDetail = datas;
      this.$bvModal.show("modal-update-post");
    },
  },
  setup() {
    const FRONTEND_MODULE_NAME = "frontend";
    if (!store.hasModule(FRONTEND_MODULE_NAME))
      store.registerModule(FRONTEND_MODULE_NAME, frontendStoreModule);
    onUnmounted(() => {
      if (store.hasModule(FRONTEND_MODULE_NAME))
        store.unregisterModule(FRONTEND_MODULE_NAME);
    });

    const {
      fetchPosts,
      postCategories,
      tableColumns,
      perPage,
      currentPage,
      totalPosts,
      dataMeta,
      refPostsListTable,
      refetchData,
      resolveStatus,
    } = usePostsList();
    const checkImg = (str) => {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" +
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      return pattern.test(str);
    };

    const resolveCategory = (id) => {
      const categoryName = postCategories.value.filter((item) => item.id == id);
      return categoryName[0].name;
    };

    return {
      fetchPosts,
      postCategories,
      tableColumns,
      perPage,
      currentPage,
      totalPosts,
      dataMeta,
      refPostsListTable,
      refetchData,
      resolveStatus,
      numberFormat,
      formatDateTime,
      baseUrl,
      resolveCategory,
      checkImg,
      // checkImgStore,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>