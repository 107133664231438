import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'

export default function usePostsList() {
	const refPostsListTable = ref(null)

	const tableColumns = [
		{ key: 'index', label: '#' },
		{ key: 'category', label: 'Category' },
		{ key: 'title', label: i18n.t('Title'),  thStyle: { width: "200px" ,  }, },
		{ key: 'title_en', label: i18n.t('English Title') },
		{ key: 'image', label: i18n.t('Image') },
		// { key: 'short_description', label: 'short_description' },
		// { key: 'short_description_en', label: 'short_description_en' },
		{ key: 'status', label: i18n.t('Status') },
		{ key: 'created_by', label: i18n.t('Created by') },
		{ key: 'created_at', label: i18n.t('Created at') },
		{ key: 'updated_at', label: i18n.t('Updated at') },
		{ key: 'action', label: i18n.t('Actions') },
	]
	const perPage = ref(25)
	const totalPosts = ref(0)
	const currentPage = ref(1)
	const postCategories = ref([])

	const refetchData = () => {
		refPostsListTable.value.refresh()
	}

	watch([currentPage], () => {
		refetchData()
	})

	const fetchPosts = (ctx, callback) => {
		store
			.dispatch('frontend/fetchPosts', {
				page: currentPage.value,
			})
			.then(response => {
				if (response.data.code == '00') {
					callback(response.data.data)
					totalPosts.value = response.data.count
					perPage.value = currentPage.value == 1 ? response.data.data.length : 25
				}
			})
	}

	store.dispatch('frontend/fetchPostCategories', {
			page: currentPage.value,
		})
		.then(response => {
			if (response.data.code == '00') {
				postCategories.value = response.data.data
			}
		})

	const dataMeta = computed(() => {
		const localItemsCount = refPostsListTable.value ? refPostsListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalPosts.value,
		}
	})

	const resolveStatus = status => {
		if (status === 1) return { label: i18n.t('Active'), variant: 'success' }
		if (status === 2) return { label: i18n.t('Inactive'), variant: 'danger' }
	}

	return {
		fetchPosts,
		postCategories,
		tableColumns,
		perPage,
		currentPage,
		totalPosts,
		dataMeta,
		refPostsListTable,
		refetchData,
		resolveStatus,
	}
}
